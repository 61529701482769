import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ActiveX Video Plugin",
  "path": "/Quick_Installation/Live_Video/ActiveX/",
  "dateChanged": "2017-11-23",
  "author": "Mike Polinowski",
  "excerpt": "Use the ActiveX Video Plugin to view your Camera's Live Video",
  "image": "./QI_SearchThumb_LiveVideo_ActiveX.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ActiveX Video Plugin' dateChanged='2017-11-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the ActiveX Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/ActiveX/' locationFR='/fr/Quick_Installation/Live_Video/ActiveX/' crumbLabel="ActiveX" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "activex-plugin-installation-on-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#activex-plugin-installation-on-windows",
        "aria-label": "activex plugin installation on windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ActiveX Plugin Installation on Windows`}</h2>
    <p>{`The ActiveX Plugin is needed to display the h.264 stream of your INSTAR HD camera in Microsoft´s Internet Explorer. Please refer to `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Live_Video/QuickTime"
      }}>{`QuickTime Plugin Setup`}</a>{` if you want to use alternative browsers like Chrome, Safari, Firefox or Opera to access your camera. In order to assure that the ActiveX Plugin runs without any problems we recommend you to keep the cameras operating system up to date. Please visit our download section from time to time to see if there are new Firmware or WebUI versions for your camera model. The newest versions can always be downloaded from `}<a href="http://download.instar.de/" target="_blank" rel="noopener noreferrer"><a parentName="p" {...{
          "href": "http://www.instar.de"
        }}>{`www.instar.de`}</a></a>{`.`}</p>
    <h2 {...{
      "id": "hd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hd-cameras",
        "aria-label": "hd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HD Cameras`}</h2>
    <h3 {...{
      "id": "windows-xp",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#windows-xp",
        "aria-label": "windows xp permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows XP`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Open the cameras web interface through the IP camera tool or by typing the cameras IP address in your Internet Explorers address bar. The web interface will automatically be opened in the web browser´s default language. On first access you will find a message in WebUI´s video area that an ActiveX Plugin needs to be installed to display the cameras live stream. Please click on ActiveX Plugin to download the installer from your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/6aca1/ActiveX_HD_01_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAU9MJ3ZH/8QAHBAAAgEFAQAAAAAAAAAAAAAAAgMAAQQSEyEi/9oACAEBAAEFAnEQFsbBayXNPeEAOf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAIBBQAAAAAAAAAAAAAAAAABMQISITJx/9oACAEBAAY/Ala2jdmahcJJP//EAB8QAAIBAgcAAAAAAAAAAAAAAAERACFRMUFhcYGR8P/aAAgBAQABPyEEMAyt5a5ImWYx5X4YSOTqVWq0/9oADAMBAAIAAwAAABADD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB4QAAICAgIDAAAAAAAAAAAAAAERACExYUFxgdHw/9oACAEBAAE/EA187jZpzDj8EIuNijAwhqKoBcJe04l+7hhU6aDc/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/e4706/ActiveX_HD_01_Windows_XP_EN.avif 230w", "/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/d1af7/ActiveX_HD_01_Windows_XP_EN.avif 460w", "/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/69c29/ActiveX_HD_01_Windows_XP_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/a0b58/ActiveX_HD_01_Windows_XP_EN.webp 230w", "/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/bc10c/ActiveX_HD_01_Windows_XP_EN.webp 460w", "/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/c1dc5/ActiveX_HD_01_Windows_XP_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/e83b4/ActiveX_HD_01_Windows_XP_EN.jpg 230w", "/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/e41a8/ActiveX_HD_01_Windows_XP_EN.jpg 460w", "/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/6aca1/ActiveX_HD_01_Windows_XP_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a9a340fbe90e1eb0ce646abcbf958f0/6aca1/ActiveX_HD_01_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please click on ActiveX Plugin to install the plugin necessary to display the camera´s video stream.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Confirm that you want to install the ClientOCX_Setup.exe ActiveX Control Element by clicking Run in the upcoming window.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/41099/ActiveX_HD_02_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHobUkVU//EABkQAQADAQEAAAAAAAAAAAAAAAABERMSQf/aAAgBAQABBQLhnbOnkJf/xAAWEQADAAAAAAAAAAAAAAAAAAABEBL/2gAIAQMBAT8BkL//xAAWEQADAAAAAAAAAAAAAAAAAAABEBL/2gAIAQIBAT8Bsr//xAAYEAADAQEAAAAAAAAAAAAAAAAAATEyEP/aAAgBAQAGPwLTNMpO/wD/xAAeEAEAAQMFAQAAAAAAAAAAAAABABEhMUGBkaGx4f/aAAgBAQABPyHGvQQ+WRwVuxK2HqLQHmO8/9oADAMBAAIAAwAAABAzL//EABYRAQEBAAAAAAAAAAAAAAAAAAAhAf/aAAgBAwEBPxAZI//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QnV63/8QAHhABAAICAQUAAAAAAAAAAAAAAQARMUHRIVFxofD/2gAIAQEAAT8QtRAfGo4urxwQK92t4IvrCJkhD2UgC7xtuf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/e4706/ActiveX_HD_02_Windows_XP_EN.avif 230w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/d1af7/ActiveX_HD_02_Windows_XP_EN.avif 460w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/df5cf/ActiveX_HD_02_Windows_XP_EN.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/a0b58/ActiveX_HD_02_Windows_XP_EN.webp 230w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/bc10c/ActiveX_HD_02_Windows_XP_EN.webp 460w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/b0a15/ActiveX_HD_02_Windows_XP_EN.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/e83b4/ActiveX_HD_02_Windows_XP_EN.jpg 230w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/e41a8/ActiveX_HD_02_Windows_XP_EN.jpg 460w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/41099/ActiveX_HD_02_Windows_XP_EN.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/41099/ActiveX_HD_02_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Confirm to execute the installation file.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Please click Next to continue the installation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/31a244cea1fc1a571d55dd0e62557928/41099/ActiveX_HD_03_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUCAwQG/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAcr9P0FDAsJr/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQMAAgQREhP/2gAIAQEAAQUCxT03ypAukxUNo/oCbE//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEJ/8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIhEP/aAAgBAgEBPwF2sOf/xAAaEAACAgMAAAAAAAAAAAAAAAAAAgEyEBEh/9oACAEBAAY/AlVulIKwKzLqM//EAB0QAAICAQUAAAAAAAAAAAAAAAERADFBIVFhgZH/2gAIAQEAAT8hA6MTeuIMviIhV1CswtljaGwVOaf/2gAMAwEAAgADAAAAENgv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAExQf/aAAgBAwEBPxBOoh//xAAXEQADAQAAAAAAAAAAAAAAAAAAARHh/9oACAECAQE/ENgVs//EAB0QAQADAAEFAAAAAAAAAAAAAAEAESExQVFxgcH/2gAIAQEAAT8QEh0KqJbKZQ0ej5BU8ak2FqErR0YUJ27wTgT/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31a244cea1fc1a571d55dd0e62557928/e4706/ActiveX_HD_03_Windows_XP_EN.avif 230w", "/en/static/31a244cea1fc1a571d55dd0e62557928/d1af7/ActiveX_HD_03_Windows_XP_EN.avif 460w", "/en/static/31a244cea1fc1a571d55dd0e62557928/df5cf/ActiveX_HD_03_Windows_XP_EN.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/31a244cea1fc1a571d55dd0e62557928/a0b58/ActiveX_HD_03_Windows_XP_EN.webp 230w", "/en/static/31a244cea1fc1a571d55dd0e62557928/bc10c/ActiveX_HD_03_Windows_XP_EN.webp 460w", "/en/static/31a244cea1fc1a571d55dd0e62557928/b0a15/ActiveX_HD_03_Windows_XP_EN.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31a244cea1fc1a571d55dd0e62557928/e83b4/ActiveX_HD_03_Windows_XP_EN.jpg 230w", "/en/static/31a244cea1fc1a571d55dd0e62557928/e41a8/ActiveX_HD_03_Windows_XP_EN.jpg 460w", "/en/static/31a244cea1fc1a571d55dd0e62557928/41099/ActiveX_HD_03_Windows_XP_EN.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/31a244cea1fc1a571d55dd0e62557928/41099/ActiveX_HD_03_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start the plugin installation.`}</p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Please click on Install in the next step to finish the installation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/07a9a85acba2ebc0f188b7255bbd4e06/41099/ActiveX_HD_04_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAerOtYUHAf/EABoQAAIDAQEAAAAAAAAAAAAAAAABAhETBBD/2gAIAQEAAQUCzgZwJc6bLrz/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BgX//xAAXEQADAQAAAAAAAAAAAAAAAAAAARES/9oACAECAQE/AdMrP//EABoQAQACAwEAAAAAAAAAAAAAAAEAMgIRIGH/2gAIAQEABj8CoShNmSeHH//EABkQAQEAAwEAAAAAAAAAAAAAAAEAEBEx8f/aAAgBAQABPyGHzZGBcCOm3f/aAAwDAQACAAMAAAAQly//xAAWEQEBAQAAAAAAAAAAAAAAAAAAMWH/2gAIAQMBAT8QyR//xAAWEQEBAQAAAAAAAAAAAAAAAAAAYQH/2gAIAQIBAT8Qvqz/xAAdEAEAAQMFAAAAAAAAAAAAAAABABAhMUFhcZHR/9oACAEBAAE/ELzcNw8hq9eIRCwAFMdHMA4Z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07a9a85acba2ebc0f188b7255bbd4e06/e4706/ActiveX_HD_04_Windows_XP_EN.avif 230w", "/en/static/07a9a85acba2ebc0f188b7255bbd4e06/d1af7/ActiveX_HD_04_Windows_XP_EN.avif 460w", "/en/static/07a9a85acba2ebc0f188b7255bbd4e06/df5cf/ActiveX_HD_04_Windows_XP_EN.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/07a9a85acba2ebc0f188b7255bbd4e06/a0b58/ActiveX_HD_04_Windows_XP_EN.webp 230w", "/en/static/07a9a85acba2ebc0f188b7255bbd4e06/bc10c/ActiveX_HD_04_Windows_XP_EN.webp 460w", "/en/static/07a9a85acba2ebc0f188b7255bbd4e06/b0a15/ActiveX_HD_04_Windows_XP_EN.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07a9a85acba2ebc0f188b7255bbd4e06/e83b4/ActiveX_HD_04_Windows_XP_EN.jpg 230w", "/en/static/07a9a85acba2ebc0f188b7255bbd4e06/e41a8/ActiveX_HD_04_Windows_XP_EN.jpg 460w", "/en/static/07a9a85acba2ebc0f188b7255bbd4e06/41099/ActiveX_HD_04_Windows_XP_EN.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/07a9a85acba2ebc0f188b7255bbd4e06/41099/ActiveX_HD_04_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Confirm the plugin installation.`}</p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: Please click on Finish.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cd493a0080697482346a605eabe2433a/41099/ActiveX_HD_05_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMGBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAc1BxaDQrGgv/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAMBAgQSExH/2gAIAQEAAQUCxZ2bxocqGKhtH7RB6f/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwGRj//EABgRAAMBAQAAAAAAAAAAAAAAAAACIQER/9oACAECAQE/AXazTp//xAAaEAACAgMAAAAAAAAAAAAAAAABAgAyEBEh/9oACAEBAAY/AlU9lBKiKzLoZ//EABsQAQACAgMAAAAAAAAAAAAAAAEAETHhIVFh/9oACAEBAAE/IeTqVy+Q0MNFFZjdtnUchqUn/9oADAMBAAIAAwAAABAb3//EABgRAAIDAAAAAAAAAAAAAAAAAAABEUFR/9oACAEDAQE/EE2iGH//xAAXEQEAAwAAAAAAAAAAAAAAAAAAEVHh/9oACAECAQE/ENgnb//EABwQAQADAAIDAAAAAAAAAAAAAAEAESFBUYGR0f/aAAgBAQABPxAibgtwlsplHfIPkKU+lNhahK0cMKEw9y/mf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd493a0080697482346a605eabe2433a/e4706/ActiveX_HD_05_Windows_XP_EN.avif 230w", "/en/static/cd493a0080697482346a605eabe2433a/d1af7/ActiveX_HD_05_Windows_XP_EN.avif 460w", "/en/static/cd493a0080697482346a605eabe2433a/df5cf/ActiveX_HD_05_Windows_XP_EN.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cd493a0080697482346a605eabe2433a/a0b58/ActiveX_HD_05_Windows_XP_EN.webp 230w", "/en/static/cd493a0080697482346a605eabe2433a/bc10c/ActiveX_HD_05_Windows_XP_EN.webp 460w", "/en/static/cd493a0080697482346a605eabe2433a/b0a15/ActiveX_HD_05_Windows_XP_EN.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd493a0080697482346a605eabe2433a/e83b4/ActiveX_HD_05_Windows_XP_EN.jpg 230w", "/en/static/cd493a0080697482346a605eabe2433a/e41a8/ActiveX_HD_05_Windows_XP_EN.jpg 460w", "/en/static/cd493a0080697482346a605eabe2433a/41099/ActiveX_HD_05_Windows_XP_EN.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cd493a0080697482346a605eabe2433a/41099/ActiveX_HD_05_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Finish the plugin installation.`}</p>
    <p><strong parentName="p">{`Step 6`}</strong>{`: Please refresh your web browser or reload the IP of your camera. You should now be able to see the video live stream in video area of the web user interface.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e263017db705b1e9a8201fd54981fd43/6aca1/ActiveX_HD_06_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAUBcoaYaf//EABgQAQEBAQEAAAAAAAAAAAAAAAECAwAR/9oACAEBAAEFArplra5Z0t7d80aHhO//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAAAAREyAhAhMf/aAAgBAQAGPwKEWnXifCmJRH//xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhMdFRcYH/2gAIAQEAAT8hfwhh8yoIEDm8FZgxew4yJu73Edu5/9oADAMBAAIAAwAAABCM7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQEAAQUBAAAAAAAAAAAAAAERACExQVFhcf/aAAgBAQABPxANTj3pr7j4CJUt+zrGELdKipjCsjX0ekxNO1Wm3fDlB14cv//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e263017db705b1e9a8201fd54981fd43/e4706/ActiveX_HD_06_Windows_XP_EN.avif 230w", "/en/static/e263017db705b1e9a8201fd54981fd43/d1af7/ActiveX_HD_06_Windows_XP_EN.avif 460w", "/en/static/e263017db705b1e9a8201fd54981fd43/69c29/ActiveX_HD_06_Windows_XP_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e263017db705b1e9a8201fd54981fd43/a0b58/ActiveX_HD_06_Windows_XP_EN.webp 230w", "/en/static/e263017db705b1e9a8201fd54981fd43/bc10c/ActiveX_HD_06_Windows_XP_EN.webp 460w", "/en/static/e263017db705b1e9a8201fd54981fd43/c1dc5/ActiveX_HD_06_Windows_XP_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e263017db705b1e9a8201fd54981fd43/e83b4/ActiveX_HD_06_Windows_XP_EN.jpg 230w", "/en/static/e263017db705b1e9a8201fd54981fd43/e41a8/ActiveX_HD_06_Windows_XP_EN.jpg 460w", "/en/static/e263017db705b1e9a8201fd54981fd43/6aca1/ActiveX_HD_06_Windows_XP_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e263017db705b1e9a8201fd54981fd43/6aca1/ActiveX_HD_06_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Reload the cameras web user interface to activate the ActiveX plugin.`}</p>
    <p><strong parentName="p">{`Note 1`}</strong>{`: You might have to start the Internet Explorer as Administrator. Just make a right click on the Internet Explorer Symbol in your Start menu and choose "Run as...".`}</p>
    <p><strong parentName="p">{`Note 2`}</strong>{`: If you use a Virus scanner such as AVAST, KASPERSKY, AVIRA, F-SECURE or AVG you have to create an exception for the IP address of the camera. The easiest way is to shortly turn of all Internet Security Software in order to check which software is blocking the ActiveX plugin from installation/execution.`}</p>
    <h3 {...{
      "id": "windows-vista7810",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#windows-vista7810",
        "aria-label": "windows vista7810 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows Vista/7/8/10`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Open the cameras web interface through the IP camera tool or by typing the cameras IP address in your Internet Explorers address bar. The web interface will automatically be opened in the web browser´s default language. On first access you will find a message in WebUI´s video area that an ActiveX Plugin needs to be installed to display the cameras live stream. Please click on ActiveX Plugin to download the installer from your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/85cbef2f27bf9027715b0567cc350af6/6aca1/ActiveX_HD_01_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAC/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAByd5RVqP/xAAbEAEBAAIDAQAAAAAAAAAAAAACAwASAQQTFP/aAAgBAQABBQKyZXpXOu3yLy3XzZGeh//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAMAAQUAAAAAAAAAAAAAAAABETISISJBof/aAAgBAQAGPwLi2jNj1PsTpl4S3c//xAAcEAACAgIDAAAAAAAAAAAAAAAAARExUXFBgfD/2gAIAQEAAT8hQ1LKXGzF3jUa2KEwoobPj7sn8pR//9oADAMBAAIAAwAAABCDz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQADAAIDAAAAAAAAAAAAAAEAEUEhYTFRkf/aAAgBAQABPxA1i5K7bEraB8QmfYK5RFIHiue37IBlDRxWK4d9T//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85cbef2f27bf9027715b0567cc350af6/e4706/ActiveX_HD_01_Windows_8_EN.avif 230w", "/en/static/85cbef2f27bf9027715b0567cc350af6/d1af7/ActiveX_HD_01_Windows_8_EN.avif 460w", "/en/static/85cbef2f27bf9027715b0567cc350af6/69c29/ActiveX_HD_01_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/85cbef2f27bf9027715b0567cc350af6/a0b58/ActiveX_HD_01_Windows_8_EN.webp 230w", "/en/static/85cbef2f27bf9027715b0567cc350af6/bc10c/ActiveX_HD_01_Windows_8_EN.webp 460w", "/en/static/85cbef2f27bf9027715b0567cc350af6/c1dc5/ActiveX_HD_01_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85cbef2f27bf9027715b0567cc350af6/e83b4/ActiveX_HD_01_Windows_8_EN.jpg 230w", "/en/static/85cbef2f27bf9027715b0567cc350af6/e41a8/ActiveX_HD_01_Windows_8_EN.jpg 460w", "/en/static/85cbef2f27bf9027715b0567cc350af6/6aca1/ActiveX_HD_01_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/85cbef2f27bf9027715b0567cc350af6/6aca1/ActiveX_HD_01_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please click on ActiveX Plugin to install the plugin necessary to display the camera´s video stream.`}</p>
    <p><strong parentName="p">{`Schritt 2`}</strong>{`: Bestätigen Sie, dass Sie das ClientOCX_Setup.exe ActiveX Control Element installieren möchten, indem Sie auf Ausführen klicken.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/6aca1/ActiveX_HD_02_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHeqBmI/8QAFhABAQEAAAAAAAAAAAAAAAAAABFC/9oACAEBAAEFAo0//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BJ//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABkQAAMAAwAAAAAAAAAAAAAAAAABESFBUf/aAAgBAQABPyGOEzWhH//aAAwDAQACAAMAAAAQiD//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAwEBPxCj/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EKx//8QAGRABAAIDAAAAAAAAAAAAAAAAAQAxESGR/9oACAEBAAE/EMpTkQCKGoQEAuf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/e4706/ActiveX_HD_02_Windows_8_EN.avif 230w", "/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/d1af7/ActiveX_HD_02_Windows_8_EN.avif 460w", "/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/69c29/ActiveX_HD_02_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/a0b58/ActiveX_HD_02_Windows_8_EN.webp 230w", "/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/bc10c/ActiveX_HD_02_Windows_8_EN.webp 460w", "/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/c1dc5/ActiveX_HD_02_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/e83b4/ActiveX_HD_02_Windows_8_EN.jpg 230w", "/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/e41a8/ActiveX_HD_02_Windows_8_EN.jpg 460w", "/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/6aca1/ActiveX_HD_02_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bb61ee35a0c9aa5bb08a6023cabc24c1/6aca1/ActiveX_HD_02_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Confirm to execute the installation file.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: When the security message pops up please select Yes.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a3f505e606eb132600a146dc74b179e3/6aca1/ActiveX_HD_03_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAAB1nc4WMb/xAAYEAACAwAAAAAAAAAAAAAAAAAAEgEQEf/aAAgBAQABBQJJFFMv/8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAEDAQE/AdK//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAESE//aAAgBAgEBPwHNEI//xAAYEAACAwAAAAAAAAAAAAAAAAAAMgEgMf/aAAgBAQAGPwJ5GNp//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAERMWFxgf/aAAgBAQABPyEBRt+kczZjQj//2gAMAwEAAgADAAAAELQP/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Qpb//xAAaEQACAgMAAAAAAAAAAAAAAAAAARFxkdHx/9oACAECAQE/ELcvZB1n/8QAHRABAAICAgMAAAAAAAAAAAAAAQARIXFRYaGx0f/aAAgBAQABPxBWy0OjPmM7PoSzLZzRKH1hrSrtmJXuf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a3f505e606eb132600a146dc74b179e3/e4706/ActiveX_HD_03_Windows_8_EN.avif 230w", "/en/static/a3f505e606eb132600a146dc74b179e3/d1af7/ActiveX_HD_03_Windows_8_EN.avif 460w", "/en/static/a3f505e606eb132600a146dc74b179e3/69c29/ActiveX_HD_03_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a3f505e606eb132600a146dc74b179e3/a0b58/ActiveX_HD_03_Windows_8_EN.webp 230w", "/en/static/a3f505e606eb132600a146dc74b179e3/bc10c/ActiveX_HD_03_Windows_8_EN.webp 460w", "/en/static/a3f505e606eb132600a146dc74b179e3/c1dc5/ActiveX_HD_03_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a3f505e606eb132600a146dc74b179e3/e83b4/ActiveX_HD_03_Windows_8_EN.jpg 230w", "/en/static/a3f505e606eb132600a146dc74b179e3/e41a8/ActiveX_HD_03_Windows_8_EN.jpg 460w", "/en/static/a3f505e606eb132600a146dc74b179e3/6aca1/ActiveX_HD_03_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a3f505e606eb132600a146dc74b179e3/6aca1/ActiveX_HD_03_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start the plugin installation.`}</p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: In the next step click on Next to continue the installation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/6aca1/ActiveX_HD_04_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBBAb/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABr6NM7sCSH//EABkQAAIDAQAAAAAAAAAAAAAAAAABAgMRBP/aAAgBAQABBQLntm7kjEVcc4Xbhp//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEn/8QAFxEAAwEAAAAAAAAAAAAAAAAAAhARQf/aAAgBAgEBPwE5i//EABoQAQEAAgMAAAAAAAAAAAAAAAIBEDEAEVH/2gAIAQEABj8CJqW/ca4XUepc/wD/xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhQTH/2gAIAQEAAT8haBUmLrLepR2BRlGvDNH/2gAMAwEAAgADAAAAEAQf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EA2t/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QSSP/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMXGBkcH/2gAIAQEAAT8QD1160DcqBat+PkNz1yoKy0AOKyeoYKnUDoGf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/e4706/ActiveX_HD_04_Windows_8_EN.avif 230w", "/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/d1af7/ActiveX_HD_04_Windows_8_EN.avif 460w", "/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/69c29/ActiveX_HD_04_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/a0b58/ActiveX_HD_04_Windows_8_EN.webp 230w", "/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/bc10c/ActiveX_HD_04_Windows_8_EN.webp 460w", "/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/c1dc5/ActiveX_HD_04_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/e83b4/ActiveX_HD_04_Windows_8_EN.jpg 230w", "/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/e41a8/ActiveX_HD_04_Windows_8_EN.jpg 460w", "/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/6aca1/ActiveX_HD_04_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d5f4b06653f5a1ae9a363bb8ce59523/6aca1/ActiveX_HD_04_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Confirm the plugin installation.`}</p>
    <p><strong parentName="p">{`Schritt 5`}</strong>{`: Klicken Sie auf Installieren, um die Installation fortzufahren.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/21729b012470d14ba201b4a7da2d7fba/6aca1/ActiveX_HD_05_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB21GkJYD/xAAYEAEAAwEAAAAAAAAAAAAAAAABABARA//aAAgBAQABBQKnlrFyv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAAAEQEiD/2gAIAQEABj8ChbOP/8QAGBABAQEBAQAAAAAAAAAAAAAAAQAhEDH/2gAIAQEAAT8h2IHNHgeov//aAAwDAQACAAMAAAAQkx//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QbX//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhQRAxUWGB/9oACAEBAAE/EMFrrwsVaLhlLdRVHrjaPkQmJ//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/21729b012470d14ba201b4a7da2d7fba/e4706/ActiveX_HD_05_Windows_8_EN.avif 230w", "/en/static/21729b012470d14ba201b4a7da2d7fba/d1af7/ActiveX_HD_05_Windows_8_EN.avif 460w", "/en/static/21729b012470d14ba201b4a7da2d7fba/69c29/ActiveX_HD_05_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/21729b012470d14ba201b4a7da2d7fba/a0b58/ActiveX_HD_05_Windows_8_EN.webp 230w", "/en/static/21729b012470d14ba201b4a7da2d7fba/bc10c/ActiveX_HD_05_Windows_8_EN.webp 460w", "/en/static/21729b012470d14ba201b4a7da2d7fba/c1dc5/ActiveX_HD_05_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/21729b012470d14ba201b4a7da2d7fba/e83b4/ActiveX_HD_05_Windows_8_EN.jpg 230w", "/en/static/21729b012470d14ba201b4a7da2d7fba/e41a8/ActiveX_HD_05_Windows_8_EN.jpg 460w", "/en/static/21729b012470d14ba201b4a7da2d7fba/6aca1/ActiveX_HD_05_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/21729b012470d14ba201b4a7da2d7fba/6aca1/ActiveX_HD_05_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Continue the plugin installation.`}</p>
    <p><strong parentName="p">{`Step 6`}</strong>{`: Click on Finish to finalize the installation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e539fe09054b2b5458cc266524da9a8e/6aca1/ActiveX_HD_06_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBBAb/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABr6NI9skCH//EABkQAAIDAQAAAAAAAAAAAAAAAAEDAAIRBP/aAAgBAQABBQJDrlwBmRXIyjjbJs//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEn/8QAFxEAAwEAAAAAAAAAAAAAAAAAAhARQf/aAAgBAgEBPwE5i//EABwQAAIBBQEAAAAAAAAAAAAAAAECEAAREiExUf/aAAgBAQAGPwJVLNs+12FY42Bn/8QAGhABAAIDAQAAAAAAAAAAAAAAAQAhEBExQf/aAAgBAQABPyFsaIXVNPQj92FMAXh//9oADAMBAAIAAwAAABDkH//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCK1//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxBaI//EAB0QAQADAAEFAAAAAAAAAAAAAAEAESExQVGBkdH/2gAIAQEAAT8QIbia0DcqXFjyfJfqnqpzNwj7Vr1AzauOJR7z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e539fe09054b2b5458cc266524da9a8e/e4706/ActiveX_HD_06_Windows_8_EN.avif 230w", "/en/static/e539fe09054b2b5458cc266524da9a8e/d1af7/ActiveX_HD_06_Windows_8_EN.avif 460w", "/en/static/e539fe09054b2b5458cc266524da9a8e/69c29/ActiveX_HD_06_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e539fe09054b2b5458cc266524da9a8e/a0b58/ActiveX_HD_06_Windows_8_EN.webp 230w", "/en/static/e539fe09054b2b5458cc266524da9a8e/bc10c/ActiveX_HD_06_Windows_8_EN.webp 460w", "/en/static/e539fe09054b2b5458cc266524da9a8e/c1dc5/ActiveX_HD_06_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e539fe09054b2b5458cc266524da9a8e/e83b4/ActiveX_HD_06_Windows_8_EN.jpg 230w", "/en/static/e539fe09054b2b5458cc266524da9a8e/e41a8/ActiveX_HD_06_Windows_8_EN.jpg 460w", "/en/static/e539fe09054b2b5458cc266524da9a8e/6aca1/ActiveX_HD_06_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e539fe09054b2b5458cc266524da9a8e/6aca1/ActiveX_HD_06_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Finish the plugin installation.`}</p>
    <p><strong parentName="p">{`Step 7`}</strong>{`: Now please refresh the page in Internet Explorer or reload the IP of the camera. Confirm the message about the ActiveX Plugin by selecting Allow.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/94df75467a15e78faa65eb8a6b66ceeb/6aca1/ActiveX_HD_07_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHJr0HY0P/EABoQAQEAAgMAAAAAAAAAAAAAAAIDAAEEExT/2gAIAQEAAQUCsmF21yNabNoqi8dN5PjoH//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAQEAAwEBAAAAAAAAAAAAAAEAAhExEjL/2gAIAQEABj8CPKl9s7yewiXcbSl//8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERIVGR4f/aAAgBAQABPyFJVlX2cYaBAoEimnu2OjF24f/aAAwDAQACAAMAAAAQYw//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAAICAgMAAAAAAAAAAAAAAQARQVExsXGhwf/aAAgBAQABPxA461TdtjMT7ZXyIzAgvVEMtOhJm8R4XsOo+oOydBnxP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94df75467a15e78faa65eb8a6b66ceeb/e4706/ActiveX_HD_07_Windows_8_EN.avif 230w", "/en/static/94df75467a15e78faa65eb8a6b66ceeb/d1af7/ActiveX_HD_07_Windows_8_EN.avif 460w", "/en/static/94df75467a15e78faa65eb8a6b66ceeb/69c29/ActiveX_HD_07_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/94df75467a15e78faa65eb8a6b66ceeb/a0b58/ActiveX_HD_07_Windows_8_EN.webp 230w", "/en/static/94df75467a15e78faa65eb8a6b66ceeb/bc10c/ActiveX_HD_07_Windows_8_EN.webp 460w", "/en/static/94df75467a15e78faa65eb8a6b66ceeb/c1dc5/ActiveX_HD_07_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94df75467a15e78faa65eb8a6b66ceeb/e83b4/ActiveX_HD_07_Windows_8_EN.jpg 230w", "/en/static/94df75467a15e78faa65eb8a6b66ceeb/e41a8/ActiveX_HD_07_Windows_8_EN.jpg 460w", "/en/static/94df75467a15e78faa65eb8a6b66ceeb/6aca1/ActiveX_HD_07_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/94df75467a15e78faa65eb8a6b66ceeb/6aca1/ActiveX_HD_07_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Reload the camera´s web user interface and enable the ActiveX plugin.`}</p>
    <p><strong parentName="p">{`Step 8`}</strong>{`: If you reload the cameras IP address again you will see the live stream of your IP camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e263017db705b1e9a8201fd54981fd43/6aca1/ActiveX_HD_06_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAUBcoaYaf//EABgQAQEBAQEAAAAAAAAAAAAAAAECAwAR/9oACAEBAAEFArplra5Z0t7d80aHhO//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAAAAREyAhAhMf/aAAgBAQAGPwKEWnXifCmJRH//xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhMdFRcYH/2gAIAQEAAT8hfwhh8yoIEDm8FZgxew4yJu73Edu5/9oADAMBAAIAAwAAABCM7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQEAAQUBAAAAAAAAAAAAAAERACExQVFhcf/aAAgBAQABPxANTj3pr7j4CJUt+zrGELdKipjCsjX0ekxNO1Wm3fDlB14cv//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e263017db705b1e9a8201fd54981fd43/e4706/ActiveX_HD_06_Windows_XP_EN.avif 230w", "/en/static/e263017db705b1e9a8201fd54981fd43/d1af7/ActiveX_HD_06_Windows_XP_EN.avif 460w", "/en/static/e263017db705b1e9a8201fd54981fd43/69c29/ActiveX_HD_06_Windows_XP_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e263017db705b1e9a8201fd54981fd43/a0b58/ActiveX_HD_06_Windows_XP_EN.webp 230w", "/en/static/e263017db705b1e9a8201fd54981fd43/bc10c/ActiveX_HD_06_Windows_XP_EN.webp 460w", "/en/static/e263017db705b1e9a8201fd54981fd43/c1dc5/ActiveX_HD_06_Windows_XP_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e263017db705b1e9a8201fd54981fd43/e83b4/ActiveX_HD_06_Windows_XP_EN.jpg 230w", "/en/static/e263017db705b1e9a8201fd54981fd43/e41a8/ActiveX_HD_06_Windows_XP_EN.jpg 460w", "/en/static/e263017db705b1e9a8201fd54981fd43/6aca1/ActiveX_HD_06_Windows_XP_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e263017db705b1e9a8201fd54981fd43/6aca1/ActiveX_HD_06_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The user interface will automatically reload and start the video stream.`}</p>
    <p><strong parentName="p">{`Note 1`}</strong>{`: You might have to start the Internet Explorer as Administrator. Just make a right click on the Internet Explorer Symbol in your Start menu and choose "Run as...".`}</p>
    <p><strong parentName="p">{`Note 2`}</strong>{`: If you use a Virus scanner such as AVAST, KASPERSKY, AVIRA, F-SECURE or AVG you have to create an exception for the IP address of the camera. The easiest way is to shortly turn of all Internet Security Software in order to check which software is blocking the ActiveX plugin from installation/execution.`}</p>
    <h2 {...{
      "id": "vga-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#vga-cameras",
        "aria-label": "vga cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VGA Cameras`}</h2>
    <h3 {...{
      "id": "windows-xp-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#windows-xp-1",
        "aria-label": "windows xp 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows XP`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Open the cameras web interface through the IP camera tool or by typing the cameras IP address in your Internet Explorers address bar. The web interface will automatically be opened in the web browser´s default language. On first access you will be shown a message that an ActiveX Plugin needs to be installed. Please click on it and choose to Install ActiveX Control Element to download the installer from your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/18ca9b707ee94913121404c471363d00/6aca1/ActiveX_01_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGfvVooEf/EABgQAQADAQAAAAAAAAAAAAAAAAMAAgQB/9oACAEBAAEFAtGhDTI11tL5zTphQuz/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBQEBAAAAAAAAAAAAAAACAAEQEiFBEf/aAAgBAQAGPwKoliK78ixNq9Dsf//EABsQAQACAgMAAAAAAAAAAAAAAAERIQAQMUFh/9oACAEBAAE/IUPCHuHUoSK1K10pxMCNGXX/2gAMAwEAAgADAAAAEJQ//8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EKys/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/EImP/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARIRAxUWGR/9oACAEBAAE/EKuIYENJuspiVQDb9TfL9lulhZQowhiY0XO+P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/18ca9b707ee94913121404c471363d00/e4706/ActiveX_01_Windows_XP_EN.avif 230w", "/en/static/18ca9b707ee94913121404c471363d00/d1af7/ActiveX_01_Windows_XP_EN.avif 460w", "/en/static/18ca9b707ee94913121404c471363d00/69c29/ActiveX_01_Windows_XP_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/18ca9b707ee94913121404c471363d00/a0b58/ActiveX_01_Windows_XP_EN.webp 230w", "/en/static/18ca9b707ee94913121404c471363d00/bc10c/ActiveX_01_Windows_XP_EN.webp 460w", "/en/static/18ca9b707ee94913121404c471363d00/c1dc5/ActiveX_01_Windows_XP_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/18ca9b707ee94913121404c471363d00/e83b4/ActiveX_01_Windows_XP_EN.jpg 230w", "/en/static/18ca9b707ee94913121404c471363d00/e41a8/ActiveX_01_Windows_XP_EN.jpg 460w", "/en/static/18ca9b707ee94913121404c471363d00/6aca1/ActiveX_01_Windows_XP_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/18ca9b707ee94913121404c471363d00/6aca1/ActiveX_01_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Internet Explorer will inform you that a plugin needs to be loaded - confirm the download.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: In the upcoming window please select "Run" in order to install the `}<em parentName="p">{`DVM-IPCam2.ocx`}</em>{` ActiveX Control Element.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/da2bd33b2ffd892635a82cc959027c6d/6aca1/ActiveX_02_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHUVFCKkf/EABgQAAMBAQAAAAAAAAAAAAAAAAACEhMg/9oACAEBAAEFAs1IQhOP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/AYF//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAS/9oACAECAQE/AaK//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEyIDH/2gAIAQEABj8C4iUSsf/EABkQAQEAAwEAAAAAAAAAAAAAAAEAEVGBMf/aAAgBAQABPyGfe/IDwuSGiQ0WC//aAAwDAQACAAMAAAAQez//xAAYEQACAwAAAAAAAAAAAAAAAAAAASExYf/aAAgBAwEBPxDAUUf/xAAXEQEBAQEAAAAAAAAAAAAAAAABADFR/9oACAECAQE/EDrKu3//xAAbEAEAAgIDAAAAAAAAAAAAAAABABFRsSGBwf/aAAgBAQABPxBs8pU8pmrCKKwEls0Sm6oAFAdT/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da2bd33b2ffd892635a82cc959027c6d/e4706/ActiveX_02_Windows_XP_EN.avif 230w", "/en/static/da2bd33b2ffd892635a82cc959027c6d/d1af7/ActiveX_02_Windows_XP_EN.avif 460w", "/en/static/da2bd33b2ffd892635a82cc959027c6d/69c29/ActiveX_02_Windows_XP_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/da2bd33b2ffd892635a82cc959027c6d/a0b58/ActiveX_02_Windows_XP_EN.webp 230w", "/en/static/da2bd33b2ffd892635a82cc959027c6d/bc10c/ActiveX_02_Windows_XP_EN.webp 460w", "/en/static/da2bd33b2ffd892635a82cc959027c6d/c1dc5/ActiveX_02_Windows_XP_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da2bd33b2ffd892635a82cc959027c6d/e83b4/ActiveX_02_Windows_XP_EN.jpg 230w", "/en/static/da2bd33b2ffd892635a82cc959027c6d/e41a8/ActiveX_02_Windows_XP_EN.jpg 460w", "/en/static/da2bd33b2ffd892635a82cc959027c6d/6aca1/ActiveX_02_Windows_XP_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/da2bd33b2ffd892635a82cc959027c6d/6aca1/ActiveX_02_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start the installation file.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: The web interface of your IP camera will automatically be reloaded and you should see the camera live stream in the internet explorer. Also you will now see the menu bar on top allowing you to activate the ActiveX functions such as "Record, Audio and Speak" which are only available by using Internet Explorer.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/15e3e0b197c9351bb71fb85229b85262/6aca1/ActiveX_03_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAaItBYDg/8QAGRABAQADAQAAAAAAAAAAAAAAAgEAAxIR/9oACAEBAAEFAkuKtj60W3WzlEuA+T//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAACAgMBAAAAAAAAAAAAAAAAAREiAiEyM//aAAgBAQAGPwKqWzplnIoxPE5g/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBcf/aAAgBAQABPyFCLW3I7AF1mS+Ir9ZYanrydEX9ITh35c//2gAMAwEAAgADAAAAEFAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAAMAAgMBAAAAAAAAAAAAAQARITFhQVGx0f/aAAgBAQABPxBVFGzk39g5Z2HH6rOpfrW3YhQhNA3KXjrWMCjyX9JVmtsG8N5Z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15e3e0b197c9351bb71fb85229b85262/e4706/ActiveX_03_Windows_XP_EN.avif 230w", "/en/static/15e3e0b197c9351bb71fb85229b85262/d1af7/ActiveX_03_Windows_XP_EN.avif 460w", "/en/static/15e3e0b197c9351bb71fb85229b85262/69c29/ActiveX_03_Windows_XP_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/15e3e0b197c9351bb71fb85229b85262/a0b58/ActiveX_03_Windows_XP_EN.webp 230w", "/en/static/15e3e0b197c9351bb71fb85229b85262/bc10c/ActiveX_03_Windows_XP_EN.webp 460w", "/en/static/15e3e0b197c9351bb71fb85229b85262/c1dc5/ActiveX_03_Windows_XP_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15e3e0b197c9351bb71fb85229b85262/e83b4/ActiveX_03_Windows_XP_EN.jpg 230w", "/en/static/15e3e0b197c9351bb71fb85229b85262/e41a8/ActiveX_03_Windows_XP_EN.jpg 460w", "/en/static/15e3e0b197c9351bb71fb85229b85262/6aca1/ActiveX_03_Windows_XP_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/15e3e0b197c9351bb71fb85229b85262/6aca1/ActiveX_03_Windows_XP_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The web user interface will automatically reload and start the video stream.`}</p>
    <p><strong parentName="p">{`Note 1`}</strong>{`: You might have to start the Internet Explorer as Administrator. Just make a right click on the Internet Explorer Symbol in your Start menu and choose "Run as...".`}</p>
    <p><strong parentName="p">{`Note 2`}</strong>{`: If you use a Virus scanner such as AVAST, KASPERSKY, AVIRA, F-SECURE or AVG you have to create an exception for the IP address of the camera. The easiest way is to shortly turn of all Internet Security Software in order to check which software is blocking the ActiveX plugin from installation/execution.`}</p>
    <h3 {...{
      "id": "windows-vista78",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#windows-vista78",
        "aria-label": "windows vista78 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows Vista/7/8`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Open the cameras web interface through the IP camera tool or by typing the cameras IP address in your Internet Explorers address bar. The web interface will automatically be opened in the web browser´s default language. On first access you will be shown a message that an ActiveX Plugin needs to be installed. Please click on Allow to download the installer from your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd59c69852eab7386efe91d3781fa8c4/6aca1/ActiveX_01_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.217391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAABABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHYpi0H/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFhAAAwAAAAAAAAAAAAAAAAAAABBR/9oACAEBAAE/IVT/2gAMAwEAAgADAAAAEIQf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAExcf/aAAgBAQABPxAVFdH/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd59c69852eab7386efe91d3781fa8c4/e4706/ActiveX_01_Windows_8_EN.avif 230w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/d1af7/ActiveX_01_Windows_8_EN.avif 460w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/69c29/ActiveX_01_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd59c69852eab7386efe91d3781fa8c4/a0b58/ActiveX_01_Windows_8_EN.webp 230w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/bc10c/ActiveX_01_Windows_8_EN.webp 460w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/c1dc5/ActiveX_01_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd59c69852eab7386efe91d3781fa8c4/e83b4/ActiveX_01_Windows_8_EN.jpg 230w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/e41a8/ActiveX_01_Windows_8_EN.jpg 460w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/6aca1/ActiveX_01_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd59c69852eab7386efe91d3781fa8c4/6aca1/ActiveX_01_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Internet Explorer will inform you that a plugin needs to be loaded - confirm the download.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Please click on Allow.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/628fa7677a8773ccf0856fcff2beded0/6aca1/ActiveX_02_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABm9dR4cwr/8QAGRABAAMBAQAAAAAAAAAAAAAAAgEDBAAQ/9oACAEBAAEFAtDUXYWk+mitSaiPP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAQEAAgMBAAAAAAAAAAAAAAECABAREiEi/9oACAEBAAY/AqCkMrtS+a5YFz5kNf/EABsQAQACAgMAAAAAAAAAAAAAAAEAERBBITFh/9oACAEBAAE/IR9R4BqoFekb3hk6drHlb3WP/9oADAMBAAIAAwAAABCoz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/EKf/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhEDFRQf/aAAgBAQABPxAu/BcCj4dQlKFB6Y941LCLWPlBSzZBsH2f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/628fa7677a8773ccf0856fcff2beded0/e4706/ActiveX_02_Windows_8_EN.avif 230w", "/en/static/628fa7677a8773ccf0856fcff2beded0/d1af7/ActiveX_02_Windows_8_EN.avif 460w", "/en/static/628fa7677a8773ccf0856fcff2beded0/69c29/ActiveX_02_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/628fa7677a8773ccf0856fcff2beded0/a0b58/ActiveX_02_Windows_8_EN.webp 230w", "/en/static/628fa7677a8773ccf0856fcff2beded0/bc10c/ActiveX_02_Windows_8_EN.webp 460w", "/en/static/628fa7677a8773ccf0856fcff2beded0/c1dc5/ActiveX_02_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/628fa7677a8773ccf0856fcff2beded0/e83b4/ActiveX_02_Windows_8_EN.jpg 230w", "/en/static/628fa7677a8773ccf0856fcff2beded0/e41a8/ActiveX_02_Windows_8_EN.jpg 460w", "/en/static/628fa7677a8773ccf0856fcff2beded0/6aca1/ActiveX_02_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/628fa7677a8773ccf0856fcff2beded0/6aca1/ActiveX_02_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start the installation file.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: The web interface of your IP camera will automatically be reloaded and you should see the camera live stream in the internet explorer. Also you will now see the menu bar on top allowing you to activate the ActiveX functions such as "Record, Audio and Speak" which are only available by using Internet Explorer.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd59c69852eab7386efe91d3781fa8c4/6aca1/ActiveX_01_Windows_8_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.217391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAABABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHYpi0H/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFhAAAwAAAAAAAAAAAAAAAAAAABBR/9oACAEBAAE/IVT/2gAMAwEAAgADAAAAEIQf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAExcf/aAAgBAQABPxAVFdH/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd59c69852eab7386efe91d3781fa8c4/e4706/ActiveX_01_Windows_8_EN.avif 230w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/d1af7/ActiveX_01_Windows_8_EN.avif 460w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/69c29/ActiveX_01_Windows_8_EN.avif 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd59c69852eab7386efe91d3781fa8c4/a0b58/ActiveX_01_Windows_8_EN.webp 230w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/bc10c/ActiveX_01_Windows_8_EN.webp 460w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/c1dc5/ActiveX_01_Windows_8_EN.webp 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd59c69852eab7386efe91d3781fa8c4/e83b4/ActiveX_01_Windows_8_EN.jpg 230w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/e41a8/ActiveX_01_Windows_8_EN.jpg 460w", "/en/static/fd59c69852eab7386efe91d3781fa8c4/6aca1/ActiveX_01_Windows_8_EN.jpg 650w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd59c69852eab7386efe91d3781fa8c4/6aca1/ActiveX_01_Windows_8_EN.jpg",
              "alt": "ActiveX Video Plugin Installation",
              "title": "ActiveX Video Plugin Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Internet Explorer will inform you that a plugin needs to be loaded - confirm the download.`}</p>
    <p><strong parentName="p">{`Note 1`}</strong>{`: You might have to start the Internet Explorer as Administrator. Just make a right click on the Internet Explorer Symbol in your Start menu and choose "Run as...".`}</p>
    <p><strong parentName="p">{`Note 2`}</strong>{`: If you use a Virus scanner such as AVAST, KASPERSKY, AVIRA, F-SECURE or AVG you have to create an exception for the IP address of the camera. The easiest way is to shortly turn of all Internet Security Software in order to check which software is blocking the ActiveX plugin from installation/execution.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      